var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"layout--main",class:[
    _vm.layoutTypeClass,
    _vm.navbarClasses,
    _vm.footerClasses,
    { 'app-page': _vm.isAppPage },
  ]},[(!_vm.disableCustomizer)?_c('the-customizer',{attrs:{"footerType":_vm.footerType,"hideScrollToTop":_vm.hideScrollToTop,"navbarType":_vm.navbarType,"navbarColor":_vm.navbarColor,"routerTransition":_vm.routerTransition},on:{"toggleHideScrollToTop":_vm.toggleHideScrollToTop,"updateFooter":_vm.updateFooter,"updateNavbar":_vm.updateNavbar,"updateNavbarColor":_vm.updateNavbarColor,"updateRouterTransition":_vm.updateRouterTransition}}):_vm._e(),_c('div',{attrs:{"id":"content-overlay"}}),(_vm.mainLayoutType === 'horizontal' && _vm.windowWidth >= 1200)?[_c('the-navbar-horizontal',{class:[
        { 'text-white': _vm.isNavbarDark && !_vm.isThemeDark },
        { 'text-base': !_vm.isNavbarDark && _vm.isThemeDark },
      ],attrs:{"navbarType":_vm.navbarType,"logo":_vm.navMenuLogo}}),(_vm.navbarType === 'static')?_c('div',{staticStyle:{"height":"62px"}}):_vm._e()]:[_c('the-navbar-horizontal',{attrs:{"navbarColor":_vm.navbarColor,"navbarType":_vm.navbarType}})],_c('div',{class:[
      { 'content-wrapper': _vm.mobileChats, 'content-wrapper2': !_vm.mobileChats },
    ]},[_c('div',{staticClass:"router-view"},[_c('div',{},[_c('transition',{attrs:{"name":_vm.routerTransition}}),_c('div',{staticClass:"content-area__content"},[_c('transition',{attrs:{"name":_vm.routerTransition,"mode":"out-in"}},[_c('router-view',{key:_vm.$store.state.componentKey,on:{"changeRouteTitle":_vm.changeRouteTitle}})],1)],1)],1)])]),_c('TheFooter'),_c('v-idle',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"loop":false,"wait":10,"duration":900},on:{"idle":_vm.onidle}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }