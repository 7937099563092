<template>
  <div class="relative">
    <notifications-sidebar
      click-not-close
      :isSidebarActive="openAllNotifications"
      @closeSidebar="toggleNotificationsSidebar"
      :notifications="notifications"
    />
    <div
      class="vx-navbar-wrapper navbar-full p-0"
      :class="[{ 'no-display': mobileChats }]"
    >
      <!-- USER META -->
      <div
        class="the-navbar__user-meta flex items-center democlss TheNavbarHorizontalcustomClassHeader lg:py-3 md:py-3 py-2 lg:px-8 md:px-8 px-2"
        v-if="activeUserInfo.displayName"
      >
        <img
          src="@/Core/assets/images/home/kotak-logo.png"
          class="logo cursor-pointer"
          @click="openHomePage"
        />
        <p class="text-calendar-blue text-2xl" v-if="myCommunityPath">
          {{ $route.meta.title }}
        </p>
        <div
          v-if="isLogin == true"
          class="flex items-center cust-navbar-mobile lg:absolute md:absolute justify-center w-full left-0 lg:gap-0 gap-4"
        >
          <div
            class="cursor-pointer smallh lg:mr-10 mr-1 mt-4"
            @click="OpenHomePage()"
            v-if="!myCommunityPath"
          >
            <!-- <feather-icon
              :class="[
                { 'router-link-active': activeLinkHome },
                'nav-link flex items-center',
              ]"
              class="mr-2 cursor-pointer"
              icon="HomeIcon"
            ></feather-icon> -->

            <p
              :class="[{ 'text-primary': activeLinkHome }, '']"
              class="font-semibold lg:block md:block hidden mr-4"
              v-if="isLogin && isReader == false"
            >
              Home
            </p>
            <img
              src="@/Client/Kotak/img/home.svg"
              class="w-8 h-8 lg:hidden md:hidden block m-0"
            />
          </div>

          <!-- <feather-icon
          :class="[
            { 'router-link-active': activeLinkMembers },
            'nav-link flex items-center'
          ]"
          class="icon "
          icon="MessageSquareIcon"
          @click="OpenMembersPage()"
          v-if="!myCommunityPath"
        ></feather-icon> -->

          <div
            class="cursor-pointer smallh lg:mr-10 mr-1 mt-4"
            @click="OpenGroupsPagePage()"
            v-if="isLogin == true && !myCommunityPath && isReader == false"
          >
            <p
              :class="[{ 'text-primary': activeLinkGroupsPage }, '']"
              class="font-semibold lg:block md:block hidden mr-4"
            >
              Channel
            </p>
            <img
              src="@/Client/Kotak/img/heart.svg"
              class="w-8 h-8 lg:hidden md:hidden block m-0"
            />
          </div>

          <div
            class="cursor-pointer smallh lg:mr-10 mr-1 mt-4"
            @click="OpenMisPage()"
            v-if="isLogin == true && !myCommunityPath && isReader == false"
          >
            <p
              :class="[{ 'text-primary': activeMisPage }, '']"
              class="font-semibold lg:block md:block hidden mr-4"
            >
              MIS
            </p>
            <img
              src="@/Client/Kotak/img/file.svg"
              class="w-8 h-8 lg:hidden md:hidden block m-0"
            />
          </div>

          <!-- <p
              :class="[{ 'text-primary': activeBlockPage }, '']"
              class="font-semibold m-0"
            >
              Block Setting
            </p> -->

          <p
            class="cursor-pointer smallh lg:mr-10 mr-1 mt-4 dropdown mb-0"
            v-if="isLogin == true && isReader == false"
          >
            <vs-dropdown>
              <feather-icon
                icon="SettingsIcon"
                class="w-6 h-6 lg:hidden md:hidden block m-0"
              />
              <p
                class="font-semibold smallh m-0 font cursor-pointer lg:block md:block hidden"
              >
                Settings
              </p>

              <vs-dropdown-menu class="droped">
                <vs-dropdown-item>
                  <div @click="OpenCategoryPage" v-if="!myCommunityPath">
                    <p
                      class="my-2"
                      :class="[{ 'text-primary': activeCategoryPage }, '']"
                    >
                      Manage Category
                    </p>
                  </div>
                </vs-dropdown-item>
                <vs-dropdown-item>
                  <div @click="OpenAuthorPage" v-if="!myCommunityPath">
                    <p
                      class="my-2"
                      :class="[{ 'text-primary': activeAuthorPage }, '']"
                    >
                      Manage Author
                    </p>
                  </div>
                </vs-dropdown-item>
                <vs-dropdown-item>
                  <div @click="OpenManagementPage" v-if="!myCommunityPath">
                    <p
                      class="my-2"
                      :class="[{ 'text-primary': activeManagementPage }, '']"
                    >
                      Channel Management
                    </p>
                  </div>
                </vs-dropdown-item>
                <vs-dropdown-item>
                  <div @click="openWhitelistIP" v-if="!myCommunityPath">
                    <p
                      class="my-2"
                      :class="[{ 'text-primary': activeAuthorPage }, '']"
                    >
                      Whitelist IP
                    </p>
                  </div>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </p>

          <div
            class="cursor-pointer smallh lg:mr-10 mr-1 mt-4"
            @click="OpenhomeMembersPage()"
            v-if="isLogin == true && !myCommunityPath && isReader == false"
          >
            <p
              :class="[{ 'text-primary': activeomeMembersPage }, '']"
              class="font-semibold lg:block md:block hidden mr-4"
            >
              Members
            </p>
            <img
              src="@/Client/Kotak/img/members.svg"
              class="w-10 h-10 lg:hidden md:hidden block m-0"
            />
          </div>

          <div
            class="cursor-pointer smallh lg:mr-10 mr-1 mt-4"
            @click="openFXCalendar"
            v-if="isLogin == true && !myCommunityPath && isReader == false"
          >
            <p
              :class="[{ 'text-primary': activeFXCalendarPage }, '']"
              class="font-semibold lg:block md:block hidden mr-4"
            >
              FX Calendar
            </p>
            <img
              src="@/Client/Kotak/img/calendar.svg"
              class="w-8 h-8 lg:hidden md:hidden block m-0"
            />
          </div>

          <!-- <feather-icon
            :class="[
              { 'router-link-active': activeLinkGroupsPage },
              'nav-link flex items-center',
            ]"
            class="icon cursor-pointer"
            icon="HeartIcon"
            @click="OpenGroupsPagePage()"
            v-if="!myCommunityPath"
          ></feather-icon> -->

          <div class="mob-plusadpost items-center flex lg:gap-2">
            <div
              class="cursor-pointer smallh lg:mr-10 mr-1"
              @click="openFXCalendar"
              v-if="
                isLogin == false ||
                isReader == true ||
                (!myCommunityPath && isOwner == false && isManager == false)
              "
            >
              <p
                :class="[{ 'text-primary': activeFXCalendarPage }, '']"
                class="font-semibold lg:block md:block hidden mr-4"
              >
                FX Calendar
              </p>
              <img
                src="@/Client/Kotak/img/calendar.svg"
                class="w-8 h-8 lg:hidden md:hidden block m-0"
              />
            </div>
            <!-- <vs-button
              icon="UserIcon"
              icon-pack="feather"
              @click="navigateToLogin"
              size="small"
              color="primary"
              svgClasses="w-6 h-6"
              class="mob-login createpost-sty font-semibold bg-dark cursor-pointer"
              v-if="isLogin == false && !myCommunityPath"
              >Login</vs-button
            >
            <vs-button
              v-if="isLogin == true && isReader == true && !myCommunityPath"
              icon="UserIcon"
              icon-pack="feather"
              @click="logoutReader"
              size="small"
              color="primary"
              svgClasses="w-6 h-6"
              class="mob-logout createpost-sty font-semibold bg-dark cursor-pointer"
              >Logout
            </vs-button> -->
            <vs-button
              icon="icon-edit"
              icon-pack="feather"
              @click="addNewData"
              size="small"
              color="primary"
              class="mob-ve-hid createpost-sty font-semibold bg-dark cursor-pointer"
              v-if="
                isLogin == true &&
                !myCommunityPath &&
                (isAdmin == true || isManager == true || isOwner == true) &&
                isReader == false
              "
              >Create Post</vs-button
            >
            <div class="flex gap-3 items-center">
              <feather-icon
                badge="0"
                icon="BellIcon"
                class="lg:mx-4 rounded-full cursor-pointer lg:block md:block hidden"
                v-if="isLogin == true && isReader == false"
                @click="openNotificationSidebar()"
              />
              <feather-icon
                icon="PlusIcon"
                @click="addNewData"
                class="cursor-pointer bg-white text-primary rounded-full lg:hidden visible"
                v-if="
                  isLogin == true &&
                  !myCommunityPath &&
                  (isAdmin == true || isManager == true || isOwner == true) &&
                  isReader == false
                "
              ></feather-icon>
            </div>
          </div>

          <!-- <feather-icon
            icon="PlusIcon"
            @click="addNewData"
            class="cursor-pointer mob-plusadpost text-white dpost lg:hidden visible"
            v-if="isLogin == true && !myCommunityPath"
          ></feather-icon> -->

          <feather-icon
            class="icon lg:hidden sm:hidden mg-right cursor-pointer"
            icon="UserIcon"
            @click="openProfilePage()"
            v-if="
              isLogin == true &&
              !myCommunityPath &&
              (isAdmin == true || isManager == true || isOwner == true) &&
              isReader == false
            "
          ></feather-icon>
        </div>
        <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
          <vs-dropdown-menu class="vx-navbar-dropdown">
            <ul style="min-width: 9rem">
              <li
                class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
                @click="$router.push('/community-user-profile').catch(() => {})"
              >
                <feather-icon icon="UserIcon" svgClasses="w-4 h-4" />
                <span class="ml-2">Profile</span>
              </li>
              <li
                class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
                @click="$router.push('/apps/email').catch(() => {})"
              >
                <feather-icon icon="MailIcon" svgClasses="w-4 h-4" />
                <span class="ml-2">Inbox</span>
              </li>
              <li
                class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
                @click="$router.push('/apps/todo').catch(() => {})"
              >
                <feather-icon icon="CheckSquareIcon" svgClasses="w-4 h-4" />
                <span class="ml-2">Tasks</span>
              </li>
              <li
                class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
                @click="$router.push('/apps/chat').catch(() => {})"
              >
                <feather-icon icon="MessageSquareIcon" svgClasses="w-4 h-4" />
                <span class="ml-2">Chat</span>
              </li>
              <li
                class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
                @click="
                  $router.push('/apps/eCommerce/wish-list').catch(() => {})
                "
              >
                <feather-icon
                  icon-pack="feather"
                  icon="icon-shopping-cart"
                  svgClasses="w-4 h-4"
                />
                <span class="ml-2">Wish List</span>
              </li>

              <li
                class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
                @click="logout"
              >
                <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4" />
                <span class="ml-2">Logout</span>
              </li>
            </ul>
          </vs-dropdown-menu>
        </vs-dropdown>

        <router-link
          tag="div"
          to="/"
          class="vx-logo cursor-pointer mx-auto flex items-center"
        ></router-link>

        <add-posts
          click-not-close
          :isSidebarActive="addNewDataSidebar"
          @closeSidebar="toggleDataSidebar"
          :data="sidebarData"
          v-if="renderAddPost"
        />

        <vs-dropdown-menu
          class="notification-dropdown dropdown-custom vx-navbar-dropdown"
        >
          <div class="notification-top text-center p-5 bg-primary text-white">
            <h3 class="text-white">{{ unreadNotifications.length }} New</h3>
            <p class="opacity-75">App Notifications</p>
          </div>
          <VuePerfectScrollbar
            ref="mainSidebarPs"
            class="scroll-area--nofications-dropdown p-0 mb-10"
            :settings="settings"
          >
            <ul class="bordered-items">
              <li
                v-for="ntf in unreadNotifications"
                :key="ntf.index"
                class="flex justify-between px-4 py-4 notification cursor-pointer"
              >
                <div class="flex items-start">
                  <feather-icon
                    :icon="ntf.icon"
                    :svgClasses="[
                      `text-${ntf.category}`,
                      'stroke-current mr-1 h-6 w-6',
                    ]"
                  ></feather-icon>
                  <div class="mx-2">
                    <span
                      class="font-medium block notification-title"
                      :class="[`text-${ntf.category}`]"
                      >{{ ntf.title }}</span
                    >
                    <small>{{ ntf.msg }}</small>
                  </div>
                </div>
                <small class="mt-1 whitespace-no-wrap">
                  {{ elapsedTime(ntf.time) }}
                </small>
              </li>
            </ul>
          </VuePerfectScrollbar>
          <div
            class="checkout-footer fixed bottom-0 rounded-b-lg text-primary w-full p-2 font-semibold text-center border border-b-0 border-l-0 border-r-0 border-solid d-theme-border-grey-light cursor-pointer"
          >
            <span>View All Notifications</span>
          </div>
        </vs-dropdown-menu>
        <!-- </vs-dropdown> -->
        <div class="cust-mobil-bell-img sm-mob-flex sm-flex-reverse">
          <div
            class="ml-2 lg:hidden sm:hidden mob-bellicon"
            v-if="
              isLogin == true &&
              !myCommunityPath &&
              (isAdmin == true || isManager == true || isOwner == true)
            "
          >
            <feather-icon
              icon="BellIcon"
              badge="0"
              class="cursor-pointer mb-1 mr-2 text-primary"
              @click.stop="NotificationSidebar = !NotificationSidebar"
            ></feather-icon>
          </div>
          <vs-button
            icon="UserIcon"
            icon-pack="feather"
            @click="navigateToLogin"
            size="small"
            color="primary"
            svgClasses="w-6 h-6"
            class="createpost-sty font-semibold bg-dark cursor-pointer"
            v-if="isLogin == false && !myCommunityPath"
            >Login</vs-button
          >
          <vs-button
            v-if="isLogin == true && isReader == true && !myCommunityPath"
            icon="UserIcon"
            icon-pack="feather"
            @click="logoutReader"
            size="small"
            color="primary"
            svgClasses="w-6 h-6"
            class="createpost-sty font-semibold bg-dark cursor-pointer"
            >Logout
          </vs-button>
          <!-- ntoifcation sidebar -->
          <vs-sidebar
            position-right
            parent="body"
            default-index="1"
            color="primary"
            class="sidebarx"
            spacer
            v-model="NotificationSidebar"
          >
            <div class="flex px-4 sidebar-topfixpart bg-primary">
              <feather-icon
                icon="ChevronLeftIcon"
                @click.stop="NotificationSidebar = !NotificationSidebar"
                class="cursor-pointer"
              ></feather-icon>
              <vs-button class="h2-class pd-10">NOTIFICATIONS</vs-button>
            </div>
            <div class="mt-16">
              <HomeNotificationSection></HomeNotificationSection>
            </div>
          </vs-sidebar>
          <!-- end notifcation sidebar -->
          <div
            class="con-img cust-profilenav flex items-center"
            v-if="
              isLogin == true &&
              !myCommunityPath &&
              (isAdmin == true || isManager == true || isOwner == true) &&
              isReader == false
            "
          >
            <UserAvatar
              :text="$store.state.user.username"
              :userID="$store.state.user.userID"
              @imageNotFound="$store.dispatch('storeUserImage', null)"
            />
            <!-- <vs-avatar
              class="bg-primary"
              :src="
                $store.state.user.userImage != null
                  ? magittUrl + $store.state.user.userImage
                  : null
              "
              :text="$store.state.user.username"
              @click="openProfilePage()"
            /> -->
            <div class="lg:hidden sm:hidden">
              <div class="">
                <h4 class="mob-hello-title">Hello</h4>
              </div>
              <div class="">
                <h4 class="mob-name-title">{{ $store.state.user.username }}</h4>
              </div>
            </div>

            <!-- <img
            v-if="token.userImage != null"
            key="onlineImg"
            :src="magittUrl + token.userImage"
            alt="user-img"
            width="40"
            height="40"
            class="rounded-full shadow-md cursor-pointer block"
            @click="openProfilePage()"
          />
          <img
            v-if="token.userImage == null"
            key="onlineImg"
            :src="magittUrl + 'uploads/defaultImages/default_user.jpg'"
            alt="user-img"
            width="40"
            height="40"
            class="rounded-full shadow-md cursor-pointer block"
            @click="openProfilePage()"
          /> -->
          </div>
        </div>
        <div class="con-img ml-3" v-if="myCommunityPath">
          <!-- <img
            src="@/Core/assets/images/logo/kotak-logo-large.jpg"
            v-if="myCommunityPath"
            class="logo"
          /> -->
          <!-- <UserAvatar
            :image="$store.state.user.userImage"
            :text="$store.state.user.username"
            :userID="$store.state.user.userID"
            @imageNotFound="$store.dispatch('storeUserImage', null)"
          /> -->
          <!-- <img
            v-if="token.userImage != null"
            key="onlineImg"
            :src="magittUrl + token.userImage"
            alt="user-img"
            width="40"
            height="40"
            class="rounded-full shadow-md cursor-pointer block"
          />
          <img
            v-if="token.userImage == null"
            key="onlineImg"
            :src="magittUrl + 'uploads/defaultImages/default_user.jpg'"
            alt="user-img"
            width="40"
            height="40"
            class="rounded-full shadow-md cursor-pointer block"
          /> -->
        </div>
      </div>
      <!-- </vs-list> -->
    </div>
  </div>
</template>

<script>
import VxAutoSuggest from "@/Core/components/vx-auto-suggest/VxAutoSuggest.vue";
import HomeNotificationSection from "@/Core/Views/pages/home/components/HomeNotificationsSection.vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import draggable from "vuedraggable";
import StatisticsCardLine from "@/Core/components/statistics-cards/StatisticsCardLine.vue";
import jwt from "jsonwebtoken";
import { videoPlayer } from "vue-video-player";
import AddPosts from "@/Core/Views/pages/AddPost/AddPosts";
import { RepositoryFactory } from "@/Core/Apicall/RepositoryFactory";
import Datepicker from "vuejs-datepicker";
import { VueAutosuggest } from "vue-autosuggest";
import { Cropper } from "vue-advanced-cropper";
import VueSimpleSuggest from "vue-simple-suggest";
import "vue-simple-suggest/dist/styles.css"; // Optional CSS
const profileRepository = RepositoryFactory.get("profile");
const notificationRepository = RepositoryFactory.get("notifications");
import { currentCommunityName } from "@/Core/config/communityDetails.js";
import "@/Core/Views/Commoncss/componentCss/header.css";
import "@/Core/Views/pages/home/components/HomeNotificationsSection.vue";
import UserAvatar from "@/Core/Views/CommonPlugins/user-avatar/UserAvatar";
const uuid = require("@/Core/config/uuid");
import NotificationsSidebar from "../../../Views/pages/home/components/NotificationsSidebar.vue";

export default {
  name: "the-navbar",

  props: {
    logo: { type: String },
    navbarType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      token: {},
      decryptedToken: null,
      userDetails: null,
      renderAddPost: false,
      chosen: "",
      query: "",
      selectedAuto: "",
      NotificationSidebar: false,
      date: null,
      userProfileImage: null,
      // Line Charts
      siteTraffic: {},
      activeUsers: {},
      newsletter: {},

      showFullSearch: false,
      stencilProps: {
        aspectRatio: 1 / 1,
      },
      addNewDataSidebar: false,
      sidebarData: {},
      unreadNotifications: [
        {
          index: 0,
          title: "New Message",
          msg: "Are your going to meet me tonight?",
          icon: "MessageSquareIcon",
          time: this.randomDate({ sec: 10 }),
          category: "primary",
        },
        {
          index: 1,
          title: "New Order Recieved",
          msg: "You got new order of goods.",
          icon: "PackageIcon",
          time: this.randomDate({ sec: 40 }),
          category: "success",
        },
        {
          index: 2,
          title: "Server Limit Reached!",
          msg: "Server have 99% CPU usage.",
          icon: "AlertOctagonIcon",
          time: this.randomDate({ min: 1 }),
          category: "danger",
        },
        {
          index: 3,
          title: "New Mail From Peter",
          msg: "Cake sesame snaps cupcake",
          icon: "MailIcon",
          time: this.randomDate({ min: 6 }),
          category: "primary",
        },
        {
          index: 4,
          title: "Bruce's Party",
          msg: "Chocolate cake oat cake tiramisu",
          icon: "CalendarIcon",
          time: this.randomDate({ hr: 2 }),
          category: "warning",
        },
      ],

      settings: {
        // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 0.6,
      },
      autoFocusSearch: false,
      showBookmarkPagesDropdown: false,
      homeNotificationCount: "",
      openAllNotifications: false,
      notifications: [],
    };
  },
  watch: {
    $route() {
      if (this.showBookmarkPagesDropdown)
        this.showBookmarkPagesDropdown = false;
    },
  },
  computed: {
    isLogin() {
      if (localStorage.getItem("token")) {
        return true;
      } else {
        return false;
      }
    },
    isReader() {
      // console.log(this.userDetails);
      if (this.userDetails) {
        if (this.userDetails.isNormalUser == true) {
          return true;
        } else {
          return false;
        }
      }
    },
    isAdmin() {
      if (this.userDetails) {
        if (this.userDetails.status != 0) {
          return true;
        } else {
          return false;
        }
      }
    },
    isOwner() {
      if (this.userDetails) {
        if (this.userDetails.isOwner) {
          return true;
        } else {
          return false;
        }
      }
    },
    isManager() {
      if (this.userDetails) {
        if (this.userDetails.isManager) {
          return true;
        } else {
          return false;
        }
      }
    },
    navbarColor() {
      let color = "#fff";
      if (this.navbarType === "sticky") color = "#f7f7f7";
      else if (this.navbarType === "static") {
        if (this.scrollY < 50) {
          color = "#f7f7f7";
        }
      }

      this.isThemedark === "dark"
        ? color === "#fff"
          ? (color = "#10163a")
          : (color = "#262c49")
        : null;

      return color;
    },
    avatarname() {
      if (this.$store.state.user.username.length > 1) {
        return this.$store.state.user.username.trim()[0].toUpperCase();
      }
    },
    isThemedark() {
      return this.$store.state.theme;
    },
    i18n_locale_img() {
      return require(`@/Core/assets/images/flags/${this.$i18n.locale}.png`);
    },
    navbarStyle() {
      let style = {};

      if (this.navbarType === "static") {
        style.transition = "all .25s ease-in-out";

        // if(this.scrollY < 50) {
        //   style.background = "#262c49"
        // }
      }

      return style;
    },
    navbarClasses() {
      return this.scrollY > 5 && this.navbarType === "static"
        ? null
        : "d-theme-dark-light-bg shadow-none";
    },
    scrollY() {
      return this.$store.state.scrollY;
    },

    // HELPER
    verticalNavMenuWidth() {
      return this.$store.state.verticalNavMenuWidth;
    },
    windowWidth() {
      return this.$store.state.windowWidth;
    },

    // I18N
    getCurrentLocaleData() {
      const locale = this.$i18n.locale;
      if (locale == "en") return { flag: "us", lang: "English" };
      else if (locale == "pt") return { flag: "br", lang: "Portuguese" };
      else if (locale == "fr") return { flag: "fr", lang: "French" };
      else if (locale == "de") return { flag: "de", lang: "German" };
    },

    // BOOKMARK & SEARCH

    starredPages() {
      return this.$store.state.starredPages;
    },
    starredPagesLimited: {
      get() {
        return this.starredPages.slice(0, 10);
      },
      set(list) {
        this.$store.dispatch("arrangeStarredPagesLimited", list);
      },
    },
    starredPagesMore: {
      get() {
        return this.starredPages.slice(10);
      },
      set(list) {
        this.$store.dispatch("arrangeStarredPagesMore", list);
      },
    },

    // CART DROPDOWN
    cartItems() {
      return this.$store.state.eCommerce.cartItems.slice().reverse();
    },

    // PROFILE
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
    user_displayName() {
      return this.activeUserInfo.displayName;
    },
    activeUserImg() {
      return this.$store.state.AppActiveUser.photoURL;
    },
    magittUrl() {
      return this.baseUrlMagitt;
    },
    playerOptions() {
      return (media) => {
        return {
          height: "360",
          fluid: true,
          autoplay: false,
          muted: true,
          language: "en",
          playbackRates: [0.7, 1.0, 1.5, 2.0],
          sources: media.sources,
          poster: media.poster,
        };
      };
    },
    imageBaseUrl() {
      return this.baseUrlMagitt;
    },
    activeLinkHome() {
      return "/home" == this.$route.path ? true : false;
    },
    activeLinkGroups() {
      return "/pages/Groups" == this.$route.path ? true : false;
    },
    activeLinkChats() {
      return "/pages/contact" == this.$route.path ? true : false;
    },
    activeLinkSocial() {
      return "/pages/Social" == this.$route.path ? true : false;
    },
    activeLinkStoreMain() {
      return "/pages/Store" == this.$route.path ? true : false;
    },
    activeLinkMembers() {
      return "/pages/MembersPage" == this.$route.path ? true : false;
    },
    activeLinkGroupsPage() {
      return "/community-channels" == this.$route.path ? true : false;
    },
    activeomeMembersPage() {
      return "/community-member-list" == this.$route.path ? true : false;
    },
    activeFXCalendarPage() {
      return "/calendar/fx" == this.$route.path ? true : false;
    },
    activeMisPage() {
      return "/pages/mis" == this.$route.path ? true : false;
    },
    activeAuthorPage() {
      return "/pages/block/author" == this.$route.path ? true : false;
    },
    activeManagementPage() {
      return "/pages/block/management" == this.$route.path ? true : false;
    },
    activeCategoryPage() {
      return "/pages/block/category" == this.$route.path ? true : false;
    },

    activeLinkActivitiesPage() {
      return "/pages/ActivitiesPage" == this.$route.path ? true : false;
    },
    activeLinkDiscussions() {
      return "/pages/DiscussionsPage" == this.$route.path ? true : false;
    },
    mobileChats() {
      return ("/pages/Social" == this.$route.path ||
        "/pages/MembersPage" == this.$route.path ||
        "/community-channels" == this.$route.path ||
        "/pages/DiscussionsPage" == this.$route.path) &&
        this.$store.state.windowWidth < 577
        ? true
        : false;
    },
    myCommunityPath() {
      // console.log(this.$route.name);
      return this.$route.name.includes("calendar") ? true : false;
    },
  },

  methods: {
    toggleNotificationsSidebar(val = false) {
      this.openAllNotifications = val;
    },
    openNotificationSidebar() {
      this.toggleNotificationsSidebar(true);
    },
    openHomePage() {
      this.$router.push("/home").catch(() => {});
    },
    openWhitelistIP() {
      this.$router.push("/pages/WhitelistIP").catch(() => {});
    },
    openFXCalendar() {
      let calendarLink = `${window.document.location.origin}/calendar/fx`;
      window.open(calendarLink, "_blank");
    },
    openProfilePage() {
      this.$router.push("/community-user-profile").catch(() => {});
      this.$store.state.memberId = this.token.userID;
    },
    toggleDataSidebar(val = false) {
      setTimeout(() => {
        if (!val) {
          this.renderAddPost = false;
        }
      }, 300);
      this.addNewDataSidebar = val;
    },
    addNewData() {
      this.renderAddPost = true;
      setTimeout(() => {
        this.sidebarData = {};
        this.toggleDataSidebar(true);
      }, 300);
    },
    activetrue() {
      this.active = true;
    },
    simpleSuggestionList() {
      return ["Vue.js", "React.js", "Angular.js"];
    },

    onSelected(item) {
      this.selectedAuto = item.item;
    },

    /**
     * This is what the <input/> value is set to when you are selecting a suggestion.
     */
    getSuggestionValue(suggestion) {
      return suggestion.item.name;
    },

    successUpload() {
      this.$vs.notify({
        color: "success",
        title: "Upload Success",
        text: "Lorem ipsum dolor sit amet, consectetur",
      });
    },
    openChats() {
      this.$router.push("/pages/contact").catch(() => {});
    },
    OpenStoreMainPage() {
      this.$router.push("/pages/Store").catch(() => {});
    },
    updateLocale(locale) {
      this.$i18n.locale = locale;
    },
    selected(item) {
      this.$router.push(item.url).catch(() => {});
      this.showFullSearch = false;
    },
    actionClicked(item) {
      // e.stopPropogation();
      this.$store.dispatch("updateStarredPage", {
        index: item.index,
        val: !item.highlightAction,
      });
    },
    showNavbarSearch() {
      this.showFullSearch = true;
    },
    showSearchbar() {
      this.showFullSearch = true;
    },
    navigateToLogin() {
      this.$router.push("/pages/kotaklogin").catch(() => {});
    },
    async logoutReader() {
      localStorage.clear();
      this.$session.destroy();
      location.reload();
      // await LDAPRepository.logout({
      //   uuid: this.token.uuid,
      // });
    },
    OpenHomePage() {
      this.$router.push("/home").catch(() => {});
    },
    Mycommunity_sidebar() {
      if (localStorage.getItem("token")) {
        this.$router.push("/pages/Mycommunity-sidebar").catch(() => {});
      } else {
        this.$router.push("/pages/customadminlogin").catch(() => {});
      }
    },
    OpenContactPage() {
      if (localStorage.getItem("token")) {
        this.$router.push("/pages/contact").catch(() => {});
      } else {
        this.$router.push("/pages/customadminlogin").catch(() => {});
      }
    },
    OpenMisPage() {
      if (localStorage.getItem("token")) {
        this.$router.push("/pages/mis").catch(() => {});
      } else {
        this.$router.push("/pages/customadminlogin").catch(() => {});
      }
    },
    OpenAuthorPage() {
      if (localStorage.getItem("token")) {
        this.$router.push("/pages/block/author").catch(() => {});
      } else {
        this.$router.push("/pages/customadminlogin").catch(() => {});
      }
    },
    OpenManagementPage() {
      if (localStorage.getItem("token")) {
        this.$router.push("/pages/block/management").catch(() => {});
      } else {
        this.$router.push("/pages/customadminlogin").catch(() => {});
      }
    },
    OpenCategoryPage() {
      if (localStorage.getItem("token")) {
        this.$router.push("/pages/block/category").catch(() => {});
      } else {
        this.$router.push("/pages/customadminlogin").catch(() => {});
      }
    },
    OpenhomeMembersPage() {
      if (localStorage.getItem("token")) {
        this.$router.push("/community-member-list").catch(() => {});
      } else {
        this.$router.push("/pages/customadminlogin").catch(() => {});
      }
    },
    OpenFXCalendarPage() {
      // if (localStorage.getItem("token")) {
      this.$router.push("/calendar/fx").catch(() => {});
      // } else {
      //   this.$router.push("/pages/customadminlogin").catch(() => {});
      // }
    },
    OpenGroupsPage() {
      if (localStorage.getItem("token")) {
        this.$router.push("/pages/Groups").catch(() => {});
      } else {
        this.$router.push("/pages/customadminlogin").catch(() => {});
      }
    },
    Open1stpage() {
      if (localStorage.getItem("token")) {
        this.$router.push("/pages/1stpage").catch(() => {});
      } else {
        this.$router.push("/pages/customadminlogin").catch(() => {});
      }
    },
    OpenMembersPage() {
      if (localStorage.getItem("token")) {
        this.$router.push("/pages/MembersPage").catch(() => {});
      } else {
        this.$router.push("/pages/customadminlogin").catch(() => {});
      }
    },
    OpenGroupsPagePage() {
      if (localStorage.getItem("token")) {
        this.$router.push("/community-channels").catch(() => {});
      } else {
        this.$router.push("/pages/customadminlogin").catch(() => {});
      }
    },
    OpenActivitiesPage() {
      if (localStorage.getItem("token")) {
        this.$router.push("/pages/ActivitiesPage").catch(() => {});
      } else {
        this.$router.push("/pages/customadminlogin").catch(() => {});
      }
    },
    OpenDiscussionsPage() {
      if (localStorage.getItem("token")) {
        this.$router.push("/pages/DiscussionsPage").catch(() => {});
      } else {
        this.$router.push("/pages/customadminlogin").catch(() => {});
      }
    },
    elapsedTime(startTime) {
      let x = new Date(startTime);
      let now = new Date();
      var timeDiff = now - x;
      timeDiff /= 1000;

      var seconds = Math.round(timeDiff);
      timeDiff = Math.floor(timeDiff / 60);

      var minutes = Math.round(timeDiff % 60);
      timeDiff = Math.floor(timeDiff / 60);

      var hours = Math.round(timeDiff % 24);
      timeDiff = Math.floor(timeDiff / 24);

      var days = Math.round(timeDiff % 365);
      timeDiff = Math.floor(timeDiff / 365);

      var years = timeDiff;

      if (years > 0) {
        return years + (years > 1 ? " Years " : " Year ") + "ago";
      } else if (days > 0) {
        return days + (days > 1 ? " Days " : " Day ") + "ago";
      } else if (hours > 0) {
        return hours + (hours > 1 ? " Hrs " : " Hour ") + "ago";
      } else if (minutes > 0) {
        return minutes + (minutes > 1 ? " Mins " : " Min ") + "ago";
      } else if (seconds > 0) {
        return seconds + (seconds > 1 ? " sec ago" : "just now");
      }

      return "Just Now";
    },
    logout() {
      // If JWT login
      if (localStorage.getItem("accessToken")) {
        localStorage.removeItem("accessToken");
        this.$router.push("/pages/login").catch(() => {});
      }

      // Change role on logout. Same value as initialRole of acj.js
      this.$acl.change("admin");
      localStorage.removeItem("userInfo");

      // This is just for demo Purpose. If user clicks on logout -> redirect
      this.$router.push("/pages/login").catch(() => {});
    },
    outside: function () {
      this.showBookmarkPagesDropdown = false;
    },

    // CART DROPDOWN
    removeItemFromCart(item) {
      this.$store.dispatch("eCommerce/toggleItemInCart", item);
    },

    // Method for creating dummy notification time
    randomDate({ hr, min, sec }) {
      let date = new Date();

      if (hr) date.setHours(date.getHours() - hr);
      if (min) date.setMinutes(date.getMinutes() - min);
      if (sec) date.setSeconds(date.getSeconds() - sec);

      return date;
    },
    async getUserDetails() {
      var self = this;
      var payload = {
        userData: {
          id: self.token.userID,
        },
        communityData: {
          communityName: currentCommunityName.communityName,
          communityID: "b2146af0-15c3-11ea-b55d-bdde97cb4377",
          communitiesType: 1,
          communitiesSubType: 0,
        },
      };
      const { data } = await profileRepository.getAllUserData(payload);
      if (data.length > 0) {
        this.userProfileImage = data[0].usersDetails_UserImage;
        this.token.userimage = data[0].usersDetails_UserImage;
      }
    },
    crop() {
      const { coordinates, canvas } = this.$refs.cropper.getResult();
      this.coordinates = coordinates;
      // You able to do different manipulations at a canvas
      // but there we just get a cropped image
      this.newGroupImage = canvas.toDataURL();

      return this.newGroupImage;
    },
    async getNotificationCount() {
      var obj = {
        communityData: {
          communityName: currentCommunityName.communityName,
        },
        notificationData: {
          userId: this.token.uuid,
        },
      };
      const { data } = await notificationRepository.getNotificationCount(obj);

      if (data) {
        this.homeNotificationCount = data;
      }
    },
    async getCurrentUserDetails() {
      // this.$vs.loading();
      let payload = {};
      if (localStorage.getItem("token")) {
        let userId = jwt.decode(localStorage.getItem("token"));
        payload = {
          userData: {
            id: userId.userID,
          },
          communityData: {
            communityName: currentCommunityName.communityName,
          },
        };

        // console.log(payload);
        const { data } = await profileRepository.getAllUserData(payload);

        console.log(data);

        if (data.length > 0) {
          // this.$vs.loading.close();
          // this.profileIsLoading = false;
          // this.userProfileDetails = data;
          this.userDetails = data[0];
          this.userDetails.status = data[0].usersDetails_Status;
          this.userDetails.isManager =
            data[0].communityManager_UserId != null ? true : false;
          this.userDetails.isOwner =
            data[0].communityManager_Is_owner == 1 ? true : false;
          this.userDetails.isNormalUser =
            data[0].usersDetails_Password == null ? true : false;
          // moment(data[0].usersDetails_DOB).format("DD-MM-YYYY");
        }
      }
    },
  },
  directives: {
    "click-outside": {
      bind: function (el, binding) {
        const bubble = binding.modifiers.bubble;
        const handler = (e) => {
          if (bubble || (!el.contains(e.target) && el !== e.target)) {
            binding.value(e);
          }
        };
        el.__vueClickOutside__ = handler;
        document.addEventListener("click", handler);
      },

      unbind: function (el) {
        document.removeEventListener("click", el.__vueClickOutside__);
        el.__vueClickOutside__ = null;
      },
    },
  },
  async mounted() {
    console.log(this.isReader);
    console.log(this.baseUrlMagitt);
    this.getCurrentUserDetails();
    if (this.$store.state.user != null) {
      if (this.$store.state.user.userID == null) {
        this.$store.dispatch(
          "storeUserToken",
          jwt.decode(localStorage.getItem("token"))
        );
      }
    }
    if (this.$store.state.uniqueDeviceId == null) {
      const uniqueId = uuid.getUniqueBrowserId();
      this.$store.dispatch("storeuniqueDeviceId", uniqueId);
    }
    // this.getUserDetails();
    //  this.getNotificationCount();
  },
  created() {
    let token = localStorage.getItem("token");
    this.token = jwt.decode(token);
  },

  components: {
    VxAutoSuggest,
    VuePerfectScrollbar,
    draggable,
    videoPlayer,
    HomeNotificationSection,
    StatisticsCardLine,
    Datepicker,
    VueAutosuggest,
    VueSimpleSuggest,
    Cropper,
    AddPosts,
    UserAvatar,
    NotificationsSidebar,
  },
};
</script>
<style lang="scss">
.TheNavbarHorizontalcustomClassHeader {
  background-color: #fff;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
}
</style>
//
<style lang="scss" scoped>
span.vs-button-text.vs-button--text {
  font-weight: 500;
  font-size: 18px;
}

input#autosuggest__input {
  padding: 10px !important;
}

#autosuggest {
  padding: 0.7rem;
  font-size: 1rem;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  color: #626262;
  width: 100%;
}

.vue-advanced-cropper__background {
  width: 100% !important;
}
.ht-62 {
  height: 62px;
}
</style>
<style lang="scss">
@import "@/Core/assets/scss/vuexy/_variables.scss";
.router-link-active > svg,
.router-link-active {
  stroke: rgba(var(--vs-primary), 1) !important;
}

.no-display {
  display: none !important;
}

.vx-navbar-wrapper {
  background: linear-gradient(
    to bottom,
    rgba(248, 248, 248, 0) 0%,
    rgba(248, 248, 248, 0.46) 0%,
    rgba(255, 255, 255, 0) 100%
  ) !important;
}
.line-vs-tabs {
  min-width: 0px !important;
}

.vdp-datepicker input {
  width: 98% !important;
}
@media screen and (max-width: 700px) {
  .box {
    width: 15% !important;
  }
}
input.vs-inputx.vs-input--input.normal.hasIcon {
  background: #d6d3d321;
  border-radius: 6px !important;
  font-family: "Montserrat", Helvetica, Arial, sans-serif !important;
  font-size: 12px !important;
}
.vs-input--input:focus {
  box-shadow: none !important;
}
.vs-input--input {
  border-radius: 0.5rem;
}

input.default-input {
  border-radius: 0px !important;
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
}

.search {
  background: #d6d3d321;
  border: 0px !important;
}

.vue-simple-suggest.designed .input-wrapper input {
  padding: 10px !important;
  background: #f4f5f6;
  border: 0px !important;
  font-family: "Montserrat", Helvetica, Arial, sans-serif !important;
  font-size: 14px !important;
}

button.vs-component.vs-button.h2-class.h3-upp-class.vs-button-primary.vs-button-filled:hover {
  box-shadow: none !important;
}

body::-webkit-scrollbar {
  width: 0 !important;
}
.vx-navbar-wrapper {
  height: 0px !important;
}
.logo {
  height: 35px;
  margin-right: 15px;
}
.font {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
}
.vs-dropdown--menu--after,
.vs-dropdown-right--menu--after {
  display: none !important;
}
</style>
