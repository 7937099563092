<template>
  <div
    class="layout--main"
    :class="[
      layoutTypeClass,
      navbarClasses,
      footerClasses,
      { 'app-page': isAppPage },
    ]"
  >
    <!-- <vx-tour
      :steps="steps"
      v-if="
        !disableThemeTour &&
          windowWidth >= 1200 &&
          mainLayoutType === 'vertical'
      "
    />-->

    <the-customizer
      v-if="!disableCustomizer"
      :footerType="footerType"
      :hideScrollToTop="hideScrollToTop"
      :navbarType="navbarType"
      :navbarColor="navbarColor"
      :routerTransition="routerTransition"
      @toggleHideScrollToTop="toggleHideScrollToTop"
      @updateFooter="updateFooter"
      @updateNavbar="updateNavbar"
      @updateNavbarColor="updateNavbarColor"
      @updateRouterTransition="updateRouterTransition"
    />

    <!-- <v-nav-menu
      :navMenuItems="navMenuItems"
      :logo="navMenuLogo"
      title="Brainstraom"
      parent=".layout--main"
    />-->

    <div id="content-overlay" />

    <!-- Navbar -->
    <template v-if="mainLayoutType === 'horizontal' && windowWidth >= 1200">
      <the-navbar-horizontal
        :navbarType="navbarType"
        :logo="navMenuLogo"
        :class="[
          { 'text-white': isNavbarDark && !isThemeDark },
          { 'text-base': !isNavbarDark && isThemeDark },
        ]"
      />

      <div style="height: 62px" v-if="navbarType === 'static'"></div>
    </template>

    <template v-else>
      <the-navbar-horizontal
        :navbarColor="navbarColor"
        :navbarType="navbarType"
      />
    </template>
    <!-- /Navbar -->

    <div
      :class="[
        { 'content-wrapper': mobileChats, 'content-wrapper2': !mobileChats },
      ]"
    >
      <div class="router-view">
        <div class="">
          <transition :name="routerTransition"></transition>

          <div class="content-area__content">
            <!-- <back-to-top
              bottom="5%"
              visibleoffset="500"
              v-if="!hideScrollToTop"
            >
              <vs-button
                icon-pack="feather"
                icon="icon-arrow-up"
                class="shadow-lg btn-back-to-top"
              />
            </back-to-top>-->

            <transition :name="routerTransition" mode="out-in">
              <router-view
                @changeRouteTitle="changeRouteTitle"
                :key="$store.state.componentKey"
              ></router-view>
            </transition>
          </div>
        </div>
      </div>
    </div>
    <TheFooter />
    <v-idle
      v-show="false"
      @idle="onidle"
      :loop="false"
      :wait="10"
      :duration="900"
    />
  </div>
</template>

<script>
import BackToTop from "vue-backtotop";
// import HNavMenu from "@/Core/layouts/components/horizontal-nav-menu/HorizontalNavMenu.vue";
// import navMenuItems from "@/Core/layouts/components/vertical-nav-menu/navMenuItems.js";
import TheCustomizer from "@/Core/layouts/components/customizer/TheCustomizer.vue";
import TheNavbarHorizontal from "@/Core/layouts/components/navbar/TheNavbarHorizontal.vue";
// import TheNavbarVertical from "@/Core/layouts/components/navbar/TheNavbarVertical.vue";
import TheFooter from "@/Core/layouts/components/TheFooter.vue";
import themeConfig from "@/../themeConfig.js";
// import VNavMenu from "@/Core/layouts/components/vertical-nav-menu/VerticalNavMenu.vue";
import urls from "@/Core/config/baseUrl.js";
import "@/main.js";
// const VxTour = () => import("@/Core/components/VxTour.vue");

export default {
  components: {
    BackToTop,
    // HNavMenu,
    TheCustomizer,
    TheFooter,
    TheNavbarHorizontal,
    // TheNavbarVertical,
    // VNavMenu,
    // VxTour
  },
  data() {
    return {
      urls,
      disableCustomizer: themeConfig.disableCustomizer,
      disableThemeTour: themeConfig.disableThemeTour,
      footerType: themeConfig.footerType || "static",
      hideScrollToTop: themeConfig.hideScrollToTop,
      isNavbarDark: false,
      navbarColor: themeConfig.navbarColor || "#fff",
      navbarType: themeConfig.navbarType || "floating",

      navMenuLogo: require("@/Core/assets/images/logo/logo.png"),
      routerTransition: themeConfig.routerTransition || "none",
      routeTitle: this.$route.meta.pageTitle,
      // steps: [
      //   {
      //     target: "#btnVNavMenuMinToggler",
      //     content: "Toggle Collapse Sidebar."
      //   },
      //   {
      //     target: ".vx-navbar__starred-pages",
      //     content:
      //       "Create your own bookmarks. You can also re-arrange them using drag & drop."
      //   },
      //   {
      //     target: ".i18n-locale",
      //     content: "You can change language from here."
      //   },
      //   {
      //     target: ".navbar-fuzzy-search",
      //     content: "Try fuzzy search to visit pages in flash."
      //   },
      //   {
      //     target: ".customizer-btn",
      //     content: "Customize template based your preference",
      //     params: {
      //       placement: "left"
      //     }
      //   }
      // ]
    };
  },
  watch: {
    $route() {
      this.routeTitle = this.$route.meta.pageTitle;
    },
    isThemeDark(val) {
      const color = this.navbarColor == "#fff" && val ? "#10163a" : "#fff";
      this.updateNavbarColor(color);
    },
    "$store.state.mainLayoutType"(val) {
      this.setNavMenuVisibility(val);
      this.disableThemeTour = true;
    },
    windowWidth(val) {
      if (val < 1200) this.disableThemeTour = true;
    },
    verticalNavMenuWidth() {
      this.disableThemeTour = true;
    },
  },
  computed: {
    bodyOverlay() {
      return this.$store.state.bodyOverlay;
    },
    contentAreaClass() {
      if (this.mainLayoutType === "vertical") {
        if (this.verticalNavMenuWidth == "default")
          return "content-area-reduced";
        else if (this.verticalNavMenuWidth == "reduced")
          return "content-area-lg";
      }
      // else if(this.mainLayoutType === "boxed") return "content-area-reduced"
      else return "content-area-full";
    },
    footerClasses() {
      return {
        "footer-hidden": this.footerType == "hidden",
        "footer-sticky": this.footerType == "sticky",
        "footer-static": this.footerType == "static",
      };
    },
    isAppPage() {
      return this.$route.path.includes("/apps/") ? true : false;
    },
    isThemeDark() {
      return this.$store.state.theme == "dark";
    },
    layoutTypeClass() {
      return `main-${this.mainLayoutType}`;
    },
    mainLayoutType() {
      return this.$store.state.mainLayoutType;
    },
    navbarClasses() {
      return {
        "navbar-hidden": this.navbarType == "hidden",
        "navbar-sticky": this.navbarType == "sticky",
        "navbar-static": this.navbarType == "static",
        "navbar-floating": this.navbarType == "floating",
      };
    },
    verticalNavMenuWidth() {
      return this.$store.state.verticalNavMenuWidth;
    },
    windowWidth() {
      return this.$store.state.windowWidth;
    },
    mobileChats() {
      return ("/pages/Social" == this.$route.path ||
        "/pages/MembersPage" == this.$route.path ||
        "/community-channels" == this.$route.path ||
        "/pages/DiscussionsPage" == this.$route.path) &&
        this.$store.state.windowWidth < 577
        ? true
        : false;
    },
  },
  methods: {
    changeRouteTitle(title) {
      this.routeTitle = title;
    },
    updateNavbar(val) {
      if (val == "static") this.updateNavbarColor("#fff");
      this.navbarType = val;
    },
    updateNavbarColor(val) {
      this.navbarColor = val;
      if (val == "#fff") this.isNavbarDark = false;
      else this.isNavbarDark = true;
    },
    updateFooter(val) {
      this.footerType = val;
    },
    updateRouterTransition(val) {
      this.routerTransition = val;
    },
    setNavMenuVisibility(layoutType) {
      if (
        (layoutType === "horizontal" && this.windowWidth >= 1200) ||
        (layoutType === "vertical" && this.windowWidth < 1200)
      ) {
        this.$store.commit("TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE", false);
        this.$store.dispatch("updateVerticalNavMenuWidth", "no-nav-menu");
      } else {
        this.$store.commit("TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE", true);
      }
    },
    toggleHideScrollToTop(val) {
      this.hideScrollToTop = val;
    },
    onidle() {
      if (localStorage.getItem("token")) {
        // alert("Your session expired");
        this.$vs.notify({
          title: "Your session expired",
          text: "Your session expired",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "warning",
          timeer: 500,
        });
        localStorage.removeItem("token");
        location.replace(window.document.location.origin);
      }
    },
  },
  created() {
    const color =
      this.navbarColor == "#fff" && this.isThemeDark
        ? "#10163a"
        : this.navbarColor;
    this.updateNavbarColor(color);
    this.setNavMenuVisibility(this.$store.state.mainLayoutType);
  },
};
</script>

<style>
@media (max-width: 577px) {
  .content-wrapper {
    padding-top: 0px;
  }
}
@media (min-width: 578px) {
  .content-wrapper {
    padding-top: 62px;
  }
}
.content-wrapper2 {
  padding-top: 62px;
}
</style>
