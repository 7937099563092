<template>
  <vs-sidebar
    position-right
    parent="body"
    default-index="1"
    color="primary"
    class="add-new-data-sidebar items-no-padding"
    spacer
    v-model="isSidebarActiveLocal"
  >
    <div class="vx-row h-full m-0 ">
      <div class="w-full">
        <div class="flex px-4 sidebar-topfixpart bg-primary">
          <feather-icon
            icon="ChevronLeftIcon"
            @click.stop="isSidebarActiveLocal = false"
            class="cursor-pointer"
          ></feather-icon>
          <vs-button class="h2-class pd-10 cust-remove-boxsh"
            >ALL NOTIFICATIONS</vs-button
          >
        </div>

        <div class="w-full">
          <ul class="mt-16">
            <li
              class="cursor-pointer"
              v-for="notification of notifications"
              :key="notification.id"
              :class="{ 'cus-greycs': notification.notificationReadStatus }"
            >
              <NotificationView
                :notification="notification"
                @closeSidebar="isSidebarActiveLocal = false"
                @notificationRead="readNotification"
              ></NotificationView>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </vs-sidebar>
</template>

<script>
import jwt from "jsonwebtoken";
import { currentCommunityName } from "@/Core/config/communityDetails.js";
import { RepositoryFactory } from "@/Core/Apicall/RepositoryFactory";
const notificationRepository = RepositoryFactory.get("notifications");
import * as moment from "moment";
import NotificationView from "./NotificationView";
export default {
  components: { NotificationView },
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    }
  },
  watch: {
    isSidebarActive(val) {
      if (!val) return;
      else {
        let token = localStorage.getItem("token");
        this.token = jwt.decode(token);
      }
    }
  },
  data() {
    return {
      token: {},
      notifications: []
    };
  },
  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive;
      },
      set(val) {
        if (!val) {
          this.$emit("closeSidebar");
        }
      }
    }
  },
  methods: {
    async getAllNotifications() {
      var obj = {
        communityData: {
          communityName: currentCommunityName.communityName
        },
        notificationData: {
          userId: this.token.uuid
        }
      };
      const { data } = await notificationRepository.getAllNotifications(obj);

      if (data.length > 0) {
        data.forEach(element => {
          element.createdAt = moment(element.createdAt).fromNow();
          this.notifications.push(element);
        });
      }
    },
    readNotification(values) {
      const index = this.notifications.findIndex(x => x._id === values.id);
      this.notifications[index].notificationReadStatus = true;
    }
  },
  created() {
    let newtoken = localStorage.getItem("token");
    this.token = jwt.decode(newtoken);
  },
  mounted() {
    this.getAllNotifications();
  }
};
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 425px;
    max-width: 100vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}
</style>

<!-- <div class="px-4 w-full flex items-center">
  <div class="media-left notifi-userparent">
    <vs-avatar
      :class="{
        'bg-primary': !notification.initiaterDetails
          .initiaterImage,
        'bg-white': notification.initiaterDetails.initiaterImage
      }"
      :src="
        notification.initiaterDetails.initiaterImage != null
          ? magittUrl +
            notification.initiaterDetails.initiaterImage
          : null
      "
      @error="
        notification.initiaterDetails.initiaterImage =
          'uploads/defaultImages/default_post.png'
      "
      :text="notification.initiaterDetails.initiaterName"
      alt="content-img"
      class="imgRadius-md bg-primary m-0 pt-2"
    />
  </div>
  <div class="w-full p-2">
    <div class="noti-text-section">
      <h5 class="h4-class fontWeight-600 leading-normal">
        {{ notification.initiaterDetails.initiaterName }}
        <span class="h4-class font-normal ml-1">
          {{ notification.notificationMessage }}
        </span>
      </h5>
    </div>
    <div class="flex noti-posttime-con">
      <p class="w-full noti-p-size text-mute-cls">
        {{ notification.createdAt }}
      </p>

      <vs-button
        color="primary"
        class="disc-key-remove-sh"
        type="flat"
        icon-pack="feather"
        icon="icon-arrow-right"
        @click="updateNotificationReadStatus(notification._id)"
      ></vs-button>
    </div>
    <!-/- <vs-button
              size="small"
              v-if="
                  notification.notificationEntityType == 'Community Join'
              "
              class="notif-app-btn mr-1"
              @click="
                  invitationResponseOfCommunityManager(
                  1,
                  notification.notificationEntityId,
                  notification._id
                  )
              "
              >Approve</vs-button
              >
              <vs-button
              size="small"
              v-if="
                  notification.notificationEntityType == 'Community Join'
              "
              class="notif-app-btn"
              @click="
                  invitationResponseOfCommunityManager(
                  2,
                  notification.notificationEntityId,
                  notification._id
                  )
              "
              >Reject</vs-button
              > -/->
    <vs-button
      size="small"
      v-if="
        notification.notificationEntityType ==
          'Public Group Request' &&
          !notification.notificationReadStatus
      "
      class="notif-app-btn mr-1"
      @click="
        approvePublicGroup(
          notification.notificationEntityId,
          notification._id
        )
      "
      >Approve</vs-button
    >
    <vs-button
      size="small"
      v-if="
        notification.notificationEntityType ==
          'Public Group Request' &&
          !notification.notificationReadStatus
      "
      class="notif-app-btn"
      @click="updateNotificationReadStatus(notification._id)"
      >Reject</vs-button
    >
  </div>
</div> -->
