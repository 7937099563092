import { render, staticRenderFns } from "./NotificationsSidebar.vue?vue&type=template&id=bc04b788&scoped=true"
import script from "./NotificationsSidebar.vue?vue&type=script&lang=js"
export * from "./NotificationsSidebar.vue?vue&type=script&lang=js"
import style0 from "./NotificationsSidebar.vue?vue&type=style&index=0&id=bc04b788&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bc04b788",
  null
  
)

export default component.exports