var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex items-center ml-2",on:{"click":function($event){return _vm.notificationAction(_vm.notification)}}},[_c('div',{staticClass:"media-left"},[_c('UserAvatar',{attrs:{"image":_vm.notification.initiaterDetails.initiaterImage,"text":_vm.notification.initiaterDetails.initiaterName},on:{"imageNotFound":function($event){_vm.notification.initiaterDetails.initiaterImage = null}}})],1),_c('div',{staticClass:"w-full p-2"},[_c('div',{staticClass:"noti-text-section"},[_c('h5',{staticClass:"h4-class fontWeight-600 leading-normal"},[_vm._v(" "+_vm._s(_vm.notification.initiaterDetails.initiaterName)+" "),(_vm.notification.howMany > 1)?_c('span',{staticClass:"h4-class font-normal ml-1"},[_vm._v(" "+_vm._s(" and " + _vm.notification.howMany + " others " + _vm.notification.notificationMessage)+" ")]):_c('span',{staticClass:"h4-class font-normal ml-1"},[_vm._v(" "+_vm._s(_vm.notification.notificationMessage)+" ")])])]),_c('div',{staticClass:"flex noti-posttime-con"},[_c('p',{staticClass:"w-full noti-p-size text-mute-cls"},[_vm._v(" "+_vm._s(_vm.notification.createdAt)+" ")]),(_vm.showArrow)?_c('vs-button',{staticClass:"disc-key-remove-sh",attrs:{"color":"primary","type":"flat","icon-pack":"feather","icon":"icon-arrow-right"}}):_vm._e()],1),(
        _vm.notification.notificationEntityType == 'Public Group Request' &&
          !_vm.notification.notificationReadStatus
      )?_c('vs-button',{staticClass:"notif-app-btn mr-1",attrs:{"size":"small"},on:{"click":function($event){return _vm.changeGroupStatus(_vm.notification.notificationEntityId, _vm.notification._id)}}},[_vm._v("Approve")]):_vm._e(),(
        _vm.notification.notificationEntityType == 'Public Group Request' &&
          !_vm.notification.notificationReadStatus
      )?_c('vs-button',{staticClass:"notif-app-btn",attrs:{"size":"small"},on:{"click":function($event){return _vm.updateNotificationReadStatus(_vm.notification._id)}}},[_vm._v("Reject")]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }