<template>
  <div
    class="flex items-center ml-2 "
    @click="notificationAction(notification)"
  >
    <div class="media-left">
      <!-- <vs-avatar
        :class="{
          'bg-primary': !notification.initiaterDetails.initiaterImage,
          'bg-white': notification.initiaterDetails.initiaterImage
        }"
        :src="
          notification.initiaterDetails.initiaterImage != null
            ? magittUrl + notification.initiaterDetails.initiaterImage
            : null
        "
        @error="
          notification.initiaterDetails.initiaterImage =
            'uploads/defaultImages/default_post.png'
        "
        :text="notification.initiaterDetails.initiaterName"
        alt="content-img"
        class="imgRadius-md bg-primary"
      /> -->
      <UserAvatar
        :image="notification.initiaterDetails.initiaterImage"
        :text="notification.initiaterDetails.initiaterName"
        @imageNotFound="notification.initiaterDetails.initiaterImage = null"
      />
    </div>
    <div class="w-full p-2">
      <div class="noti-text-section">
        <h5 class="h4-class fontWeight-600 leading-normal">
          {{ notification.initiaterDetails.initiaterName }}
          <span
            class="h4-class font-normal ml-1"
            v-if="notification.howMany > 1"
          >
            {{
              " and " +
                notification.howMany +
                " others " +
                notification.notificationMessage
            }}
          </span>
          <span class="h4-class font-normal ml-1" v-else>
            {{ notification.notificationMessage }}
          </span>
        </h5>
      </div>
      <div class="flex noti-posttime-con">
        <p class="w-full noti-p-size text-mute-cls">
          {{ notification.createdAt }}
        </p>

        <vs-button
          v-if="showArrow"
          color="primary"
          class="disc-key-remove-sh"
          type="flat"
          icon-pack="feather"
          icon="icon-arrow-right"
        ></vs-button>
      </div>
      <!-- <vs-button
        size="small"
        v-if="
          notification.notificationEntityType == 'Community Join'
        "
        class="notif-app-btn mr-1"
        @click="
          invitationResponseOfCommunityManager(
            1,
            notification.notificationEntityId,
            notification._id
          )
        "
        >Approve</vs-button
      >
      <vs-button
        size="small"
        v-if="
          notification.notificationEntityType == 'Community Join'
        "
        class="notif-app-btn"
        @click="
          invitationResponseOfCommunityManager(
            2,
            notification.notificationEntityId,
            notification._id
          )
        "
        >Reject</vs-button
      > -->
      <vs-button
        size="small"
        v-if="
          notification.notificationEntityType == 'Public Group Request' &&
            !notification.notificationReadStatus
        "
        class="notif-app-btn mr-1"
        @click="
          changeGroupStatus(notification.notificationEntityId, notification._id)
        "
        >Approve</vs-button
      >
      <vs-button
        size="small"
        v-if="
          notification.notificationEntityType == 'Public Group Request' &&
            !notification.notificationReadStatus
        "
        class="notif-app-btn"
        @click="updateNotificationReadStatus(notification._id)"
        >Reject</vs-button
      >
    </div>
  </div>
</template>

<script>
import UserAvatar from "@/Core/Views/CommonPlugins/user-avatar/UserAvatar";
import jwt from "jsonwebtoken";
import { currentCommunityName } from "@/Core/config/communityDetails.js";
import { RepositoryFactory } from "@/Core/Apicall/RepositoryFactory";
const notificationRepository = RepositoryFactory.get("notifications");
const groupsRepository = RepositoryFactory.get("groups");
const userRepository = RepositoryFactory.get("user");
const postsRepository = RepositoryFactory.get("posts");
import * as moment from "moment";
export default {
  props: {
    notification: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    UserAvatar
  },
  data() {
    return {
      notifications: [],
      token: {},
      noArrowTypes: ["User Blocked"]
    };
  },
  methods: {
    async updateNotificationReadStatus(id) {
      this.$vs.loading.close();
      let payload = {
        notificationData: {
          id: id
        },
        communityData: {
          communityName: currentCommunityName.communityName
        }
      };
      const { data } = await notificationRepository.updateNotification(payload);
      if (data) {
        this.$emit("notificationRead", { id: id });
        this.$vs.loading.close();
        //  console.log(data, "update notification");
      }
    },
    async invitationResponseOfUser(status, groupId) {
      this.$vs.loading();
      var obj = {
        communityData: {
          communityName: currentCommunityName.communityName
        },
        invitationData: {
          status: status,
          userId: this.token.userID,
          groupId: groupId,
          ownerName: this.token.username,
          postingRights: 1
        }
      };
      const { data } = await groupsRepository.invitationResponseOfUser(obj);
      //   console.log(data);
      if (data) {
        this.$vs.loading.close();
      }
    },
    async invitationResponseOfCommunityManager(status, userId, id) {
      this.$vs.loading();
      var obj = {
        communityData: {
          communityName: currentCommunityName.communityName
        },
        status: status,
        uuid: userId
      };
      const { data } = await userRepository.changeUserStatus(obj);
      //    console.log(data);
      if (data) {
        this.$vs.loading.close();
        this.updateNotificationReadStatus(id);
      }
    },
    async changeGroupStatus(groupId, id) {
      this.$vs.loading();
      var obj = {
        communityData: {
          communityName: currentCommunityName.communityName
        },
        groupData: {
          ownerName: this.token.username,
          groupId: groupId,
          status: 1
        }
      };
      const { data } = await groupsRepository.changeGroupStatus(obj);
      //  console.log(data);
      if (data) {
        this.$vs.loading.close();
        this.updateNotificationReadStatus(id);
      }
    },
    async approvePublicGroup(groupId, id) {
      this.$vs.loading();
      var obj = {
        communityData: {
          communityName: currentCommunityName.communityName
        },
        name: this.token.username,
        groupId: groupId
      };
      const { data } = await groupsRepository.insertAllMembersInGroup(obj);
      //  console.log(data);
      if (data) {
        this.$vs.loading.close();
        this.updateNotificationReadStatus(id);
      }
    },
    notificationAction(notification) {
      this.$emit("closeSidebar");
      setTimeout(() => {
        this.notificationActions(notification);
      }, 100);
    },
    notificationActions(notification) {
      if (notification.notificationEntityType == "Post") {
        this.postNotificationAction(notification.notificationEntityId);
      }
      if (notification.notificationEntityType == "Group Join Request") {
        this.groupRouteNotificationAction(notification.notificationEntityId);
      }
      if (notification.notificationEntityType == "Group Invitation") {
        this.groupRouteNotificationAction(notification.notificationEntityId);
      }
      if (notification.notificationEntityType == "Community Join") {
        this.communityJoinNotificationAction();
      }
      this.updateNotificationReadStatus(notification._id);
    },
    postNotificationAction(post_UniqueId) {
      this.getPost(post_UniqueId);
      // let self = this;
      // let rawString = {
      //   postId: post_UniqueId,
      //   community: currentCommunityName.communityName,
      //   communityColor: localStorage.getItem("communityColor"),
      //   communityId: localStorage.getItem("communityID")
      // };
      // rawString = JSON.stringify(rawString);
      // let postLink =
      //   window.document.location.origin +
      //   "/posts?pid=" +
      //   btoa(rawString);
      // // this.$router
      // //   .push("/posts?pid=" + btoa(rawString))
      // //   .catch(() => {});
      // let routeData = this.$router.resolve({
      //   path: "/posts?pid=" + btoa(rawString)
      // });
      // window.open(routeData.href, "_blank");
    },
    groupRouteNotificationAction(groupId) {
      this.$store.state.groupId = groupId;
      this.$router
        .push({
          name: "page-GroupsPage",
          params: { groupId: this.$store.state.groupId }
        })
        .catch(() => {});
    },
    communityJoinNotificationAction() {
      this.$router
        .push({
          name: "page-viewmembers",
          params: { filter: "Pending" }
        })
        .catch(() => {});
    },
    async getPost(postId) {
      this.$vs.loading();
      let obj = {
        userData: {
          id: this.token.userID,
          uuid: this.token.uuid
        },
        groupData: {
          id: postId
        },
        communityData: {
          communityName: currentCommunityName.communityName
        }
      };

      const { data } = await postsRepository.getPost(obj);

      if (data) {
        this.$vs.loading.close();
        if (data.length > 0) {
          let groupPost = {};
          data.forEach(element => {
            element.post_group_mapping_created_datetimestamp = moment(
              element.post_group_mapping_created_datetimestamp
            ).fromNow();
            if (element.image != null) {
              if (element.image.includes("uploads/postImages")) {
                element.image = this.magittUrl + element.image;
              }
            }
            if (element.video_url != null) {
              if (element.video_url.includes("uploads/postVideos")) {
                element.video_url = this.magittUrl + element.video_url;
              }
            }
            if (element.audio_url != null) {
              if (element.audio_url.includes("uploads/postAudios")) {
                element.audio_url = this.magittUrl + element.audio_url;
              }
            }
            if (element.featured_image != null) {
              if (element.featured_image.includes("uploads/postImages")) {
                element.featured_image =
                  this.magittUrl + element.featured_image;
              }
            }
            if (element.PollQuestion !== null) {
              element.VotedIds =
                element.VotedIds !== null ? element.VotedIds : "";
              element.AnswersIds =
                element.AnswersIds !== null
                  ? element.AnswersIds.split(",")
                  : [];
              element.PollAnswers =
                element.PollAnswers !== null
                  ? element.PollAnswers.split(",")
                  : [];
              element.AnswersVotes =
                element.AnswersVotes !== null
                  ? element.AnswersVotes.split(",")
                  : [];
            }
            element.comments = [];
            element.newComment = "";
            element.comments = [];
            element.newComment = "";
            element.first_comment_replies = [];
            groupPost = element;

            this.$store.dispatch("storePostPopupData", groupPost);
          });
        }
      } else {
        this.$vs.loading.close();
      }
    }
  },
  computed: {
    magittUrl() {
      return this.baseUrlMagitt;
    },
    showArrow() {
      return !this.noArrowTypes.includes(
        this.notification.notificationEntityType
      );
    }
  },
  mounted() {},
  created() {
    let token = localStorage.getItem("token");
    this.token = jwt.decode(token);
    //  console.log(this.token);
  }
};
</script>
