<template>
  <div>
    <div v-if="isLogin == false" class="bg-primary block lg:py-7 md:py-7 py-5">
      <div
        class="flex w-full lg:justify-between md:justify-between justify-around items-center lg:px-24 sm-10px"
      >
        <p class="text-white text-center mb-0">
          &copy; Kotak Mahindra Bank 2022, All Rights Reserved. &nbsp;
        </p>
        <span class="text-white cursor-pointer" @click="adminLogin"
          >Admin Login</span
        >

        <!-- <div class="flex" @click="adminLogin">
          <p class="text-white text-center">
             Admin Login
          </p>
        </div> -->
      </div>
    </div>
    <div
      v-if="isLogin == true"
      class="bg-primary lg:block md:block hidden lg:py-7 md:py-7 py-5"
    >
      <div
        class="lg:flex w-full lg:justify-between justify-center items-center lg:px-24"
      >
        <p class="text-white text-center mb-0">
          &copy; Kotak Mahindra Bank 2022, All Rights Reserved. &nbsp;
        </p>
        <span class="text-white cursor-pointer" @click="adminLogin"
          >Admin Login</span
        >

        <!-- <div class="flex" @click="adminLogin">
          <p class="text-white text-center">
             Admin Login
          </p>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      centerDialogVisible: false,
      centerDialogVisiblec: false,
      lastRoute: "",
      btnName: "",
    };
  },
  methods: {
    adminLogin() {
      this.$router.push("/pages/customadminlogin").catch(() => {});
    },
  },
  computed: {
    isLogin() {
      if (localStorage.getItem("token")) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
<style>
@media screen and (max-width: 700px) {
  .sm-10px {
    font-size: 10px;
  }
}
</style>
