<template>
  <div class="w-full">
    <notifications-sidebar
      :isSidebarActive="openAllNotifications"
      @closeSidebar="toggleNotificationsSidebar"
      :notifications="notifications"
    />
    <div class="mob-viw-hidden">
      <span class="h2-class">Unread Notifications</span>
      <div class="notif-icon">
        <feather-icon
          :class="['nav-link flex items-center']"
          class="cursor-pointer"
          icon="BellIcon"
          :badge="notifications.length"
          @click="openNotificationSidebar()"
        ></feather-icon>
      </div>
    </div>
    <div class="w-full mobl-notif-ul">
      <div class="vx-main notif-top-md">
        <vx-card class="bShadow-remove">
          <div class="notifi-head-scroll">
            <ul class="notif-ul">
              <li
                class="cursor-pointer friend-suggestion Carsouel_resp_width_flex_assign_notfi"
                v-for="notification of notifications"
                :key="notification.id"
              >
                <NotificationView
                  :notification="notification"
                  @notificationRead="readNotification"
                ></NotificationView>
              </li>
            </ul>
          </div>
        </vx-card>
      </div>
      <div class="">
        <vs-button
          color="white"
          type="filled"
          class="w-full font-semibold cust-rembord text-primary cust-bshadow-rem"
          @click="openNotificationSidebar()"
          >VIEW ALL NOTIFICATIONS</vs-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import jwt from "jsonwebtoken";
import { currentCommunityName } from "@/Core/config/communityDetails.js";
import { RepositoryFactory } from "@/Core/Apicall/RepositoryFactory";
const notificationRepository = RepositoryFactory.get("notifications");
import * as moment from "moment";
import NotificationsSidebar from "./NotificationsSidebar";
import NotificationView from "./NotificationView";
export default {
  components: { NotificationsSidebar, NotificationView },
  data() {
    return {
      openAllNotifications: false,
      notifications: [],
      token: {},
    };
  },
  methods: {
    toggleNotificationsSidebar(val = false) {
      // setTimeout(() => {
      // //   if (!val) {
      // //     this.renderNotificationsSidebar = false;
      // //   }
      // // }, 300);
      this.openAllNotifications = val;
    },
    openNotificationSidebar() {
      // this.renderNotificationsSidebar = true;
      // setTimeout(() => {
      this.toggleNotificationsSidebar(true);
      // }, 300);
    },
    async getAllNotifications() {
      var obj = {
        communityData: {
          communityName: currentCommunityName.communityName,
        },
        notificationData: {
          userId: this.token.uuid,
        },
      };
      const { data } = await notificationRepository.getNotifications(obj);

      if (data.length > 0) {
        data.forEach((element) => {
          element.createdAt = moment(element.createdAt).fromNow();
          this.notifications.push(element);
        });
      }
    },
    readNotification(values) {
      this.notifications.splice(
        this.notifications.findIndex((x) => x._id === values.id),
        1
      );
    },
    showPostPopup(values) {
      this.$emit("showPopup", values);
    },
  },
  mounted() {
    this.getAllNotifications();
    this.$store.watch(this.$store.getters.postPopup, (data) => {
      this.showPostPopup(data);
    });
  },
  created() {
    let token = localStorage.getItem("token");
    this.token = jwt.decode(token);
  },
};
</script>

<!-- <div class="media-left notifi-userparent">
  <vs-avatar
    :class="{
      'bg-primary': !notification.initiaterDetails
        .initiaterImage,
      'bg-white': notification.initiaterDetails.initiaterImage
    }"
    :src="
      notification.initiaterDetails.initiaterImage != null
        ? magittUrl +
          notification.initiaterDetails.initiaterImage
        : null
    "
    @error="
      notification.initiaterDetails.initiaterImage =
        'uploads/defaultImages/default_post.png'
    "
    :text="notification.initiaterDetails.initiaterName"
    alt="content-img"
    class="imgRadius-md bg-primary"
  />
</div>
<div class="w-full p-2">
  <div class="noti-text-section">
    <h5 class="h4-class fontWeight-600 leading-normal">
      {{ notification.initiaterDetails.initiaterName }}
      <span class="h4-class font-normal ml-1">
        {{ notification.notificationMessage }}
      </span>
    </h5>
  </div>
  <div class="flex noti-posttime-con">
    <p class="w-full noti-p-size text-mute-cls">
      {{ notification.createdAt }}
    </p>

    <vs-button
      color="primary"
      class="disc-key-remove-sh"
      type="flat"
      icon-pack="feather"
      icon="icon-arrow-right"
    ></vs-button>
  </div>
  <!-/- <vs-button
      size="small"
      v-if="
        notification.notificationEntityType == 'Community Join'
      "
      class="notif-app-btn mr-1"
      @click="
        invitationResponseOfCommunityManager(
          1,
          notification.notificationEntityId,
          notification._id
        )
      "
      >Approve</vs-button
    >
    <vs-button
      size="small"
      v-if="
        notification.notificationEntityType == 'Community Join'
      "
      class="notif-app-btn"
      @click="
        invitationResponseOfCommunityManager(
          2,
          notification.notificationEntityId,
          notification._id
        )
      "
      >Reject</vs-button
    > -/->
  <vs-button
    size="small"
    v-if="
      notification.notificationEntityType ==
        'Public Group Request'
    "
    class="notif-app-btn mr-1"
    @click="
      changeGroupStatus(
        notification.notificationEntityId,
        notification._id
      )
    "
    >Approve</vs-button
  >
  <vs-button
    size="small"
    v-if="
      notification.notificationEntityType ==
        'Public Group Request'
    "
    class="notif-app-btn"
    @click="updateNotificationReadStatus(notification._id)"
    >Reject</vs-button
  >
</div> -->
